import React from 'react';
import BigLogo from './BigLogo';
import ContactForm from './ContactForm';
import AccesoClientes from './AccesoClientes';

function AppMain() {
  return (
    <div className='AppMain'>
      <AccesoClientes />
      <div className='mt-4'>
        <BigLogo />
        <ContactForm />
      </div>
      <footer className='text-center mt-5'>
        <p style={{color:"#343c45"}}>&copy; {(new Date()).getFullYear()} Brodi - Todos los derechos reservados</p>
      </footer>
    </div>
  )
}

export default AppMain