import React from 'react';
import './AccesoClientes.css';

function AccesoClientes() {
  return (
    <div className='position-absolute top-0 end-0 m-3'>
      <div className='input-group'>
        <a 
        className='btn btn-secondary'
        href='https://brodi.com.ar/clientes'
        rel='noreferrer'
        >Acceso Clientes</a>
        <a 
        className='btn btn-secondary GoogleButton'
        href='https://accounts.google.com/o/oauth2/v2/auth?response_type=code&access_type=online&client_id=924406461554-fs75d5grgd61qovvbb3c3mabd97u5n4f.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fwww.brodi.com.ar%2Fclientes%2Fsession-detect.php&state&scope=email%20profile&approval_prompt=auto'
        rel='noreferrer'
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 16 16"><path fill="currentColor" d="M15.545 6.558a9.4 9.4 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.7 7.7 0 0 1 5.352 2.082l-2.284 2.284A4.35 4.35 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.8 4.8 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301c1.078 0 2.004-.276 2.722-.764h-.003a3.7 3.7 0 0 0 1.599-2.431H8v-3.08z"/></svg>
        </a>
      </div>
    </div>
  )
}

export default AccesoClientes