import React, { useState, useEffect} from 'react';
import './ContactForm.css';
import ViasDeContacto from './ViasDeContacto';

function ContactForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [telefono, setTelefono] = useState('');
  const [mensaje, setMensaje] = useState('');
  const [sendByEmail, setSendByEmail] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [mensajeEnviado, setMensajeEnviado] = useState(null);

  useEffect(() => {
    if (email!=='') {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      setIsValidEmail(emailRegex.test(email));
    }
  }, [email]);

  const handlerEnviar = () => {
    // post to server/form-action.php
    console.log('Enviando mensaje:', mensaje);
    console.log('Enviando a:', email);
    fetch( process.env.REACT_APP_BASE_DIR +  'server/form-action.php', {
      method: 'POST',
      body: JSON.stringify({
        name: name,
        email: email,
        telefono: telefono,
        mensaje: mensaje
      })
    })
    .then(response => response.json())
    .then(data => {
      setMensajeEnviado(data.success);
      if(data.success) {
        setName('');
        setEmail('');
        setTelefono('');
        setMensaje('');
        setSendByEmail(false);
      }
    })
    .catch(error => {
      setMensajeEnviado(false);
    });
  };

  return (
    <div class="d-flex justify-content-center">
      {
        mensajeEnviado!==null && <div>
        <div className={`alert alert-${mensajeEnviado?'success':'danger'}`}>
          {mensajeEnviado?'Mensaje enviado con éxito.':'Hubo un error al enviar el mensaje.'}
        </div>
        <button
              className='mx-1 my-2 btn btn-secondary'
              onClick={()=>{setMensajeEnviado(null);}}
              >Volver</button>
        </div>
      }
      {mensajeEnviado===null &&
      <div className='ContactForm my-3'>
        <textarea 
          rows='3'
          placeholder='¿Qué te trae por acá?' 
          className={'form-control mb-3'+(sendByEmail&&mensaje!==''?' is-valid':'')}
          value={mensaje}
          onChange={e => setMensaje(e.target.value)}
          required={true}
        />
        <div 
          className={`py-3 my-3 ${mensaje!=='' ? 'fade show' : 'fade'}`}
          style={{ transition: 'opacity 0.5s' }}
        >
          { mensaje!=='' && !sendByEmail &&
          <ViasDeContacto mensaje={mensaje} sendByEmail={()=>setSendByEmail(true)} />
          }
          
          { sendByEmail && 
          <input 
            type='email'
            className={'form-control mb-3'+(email===''?'':(isValidEmail?' is-valid':' is-invalid'))}
            placeholder='Ingrese su email'
            value={email}
            onChange={e => setEmail(e.target.value)}
            required={true}
          />
          }
          {
            sendByEmail && !isValidEmail && email!=='' &&
            <div className='invalid-feedback'>
              Ingrese un email válido
            </div>
          }
          { isValidEmail && sendByEmail &&
          <button 
            className='mx-1 my-2 btn btn-primary'
            onClick={handlerEnviar}
          >Enviar</button>
          }
          { sendByEmail &&
            <button
              className='mx-1 my-2 btn btn-secondary'
              onClick={()=>{setSendByEmail(false);}}
              >Volver</button>
          }
        </div>
      </div>
      }
      
    </div>
  );
  /*
  return (
    <div class="d-flex justify-content-center">
      <div className='ContactForm'>
          <div className='input-group mb-2'>
            <label className='input-group-text'>Nombre</label>
            <input type='text' className='form-control' id='name' />
          </div>
          <div className='input-group mb-2'>
            <label className='input-group-text'>Email</label>
            <input type='email' className='form-control' id='email' />
          </div>
          <div className='input-group mb-2'>
            <label className='input-group-text'>Teléfono</label>
            <input type='email' className='form-control' id='telefono' />
          </div>
          <div className='input-group'>
            <textarea placeholder='Contanos... ¿En qué podemos ayudarte?' className='form-control' id='mensaje' />
          </div>
          
          <button type='submit' className='btn btn-primary'>Enviar</button>
      
      </div>
    </div>
  )
}
*/
}
export default ContactForm;