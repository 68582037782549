import React from 'react'

function Email({sendByEmail}) {
  return (
    <div className='d-inline mx-2'>
      <a 
        onClick={sendByEmail}
        title='Enviar por Email'
        className='btn btn-primary' 
        >
          Enviar por <svg className='mb-1' xmlns="http://www.w3.org/2000/svg" width="1.4rem" height="1.4rem" viewBox="0 0 24 24"><g fill="none" stroke="white" strokeLinecap="round" strokeWidth="2"><rect width="18" height="14" x="3" y="5" stroke-dasharray="64" stroke-dashoffset="64" rx="1"><animate fill="freeze" attributeName="stroke-dashoffset" dur="0.6s" values="64;0"/></rect><path stroke-dasharray="24" stroke-dashoffset="24" d="M3 6.5L12 12L21 6.5"><animate fill="freeze" attributeName="stroke-dashoffset" begin="0.6s" dur="0.4s" values="24;0"/></path></g></svg>
      </a>
    </div>
  )
}

export default Email