import React from 'react';
import './BigLogo.css';

function BigLogo() {
  return (
    <img 
      className='BigLogo' 
      src={process.env.REACT_APP_BASE_DIR + 'logo-brodi-738.png'} 
    />
  )
}

export default BigLogo