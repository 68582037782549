import React from 'react';
import WhatsApp from './WhatsApp';
import Email from './Email';
import './ViasDeContacto.css';

function ViasDeContacto({mensaje, sendByEmail}) {
  return (
    <div className='ViasDeContacto'>
      <WhatsApp mensaje={mensaje} />
      <Email mensaje={mensaje} sendByEmail={sendByEmail} />
    </div>
  )
}

export default ViasDeContacto